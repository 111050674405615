import React from "react";
import {
    Root,
    ContentWrapper,
    GatsbyBackgroundImage as BackgroundImage,
    Content,
    Header,
    Description 
} from './page-hero-styles';
import { CenterWrapper } from '../../primitives/index';

const Background = ({ images, children }) => (
    <BackgroundImage
        Tag="section"
        fluid={images[0].src.childImageSharp.fluid}>
            { children }
    </BackgroundImage>
);

interface PageHeroProps {
    images: any;
    title: string;
    description: string;
}

const PageHero = (props: PageHeroProps) => {
    const RootWrapper = props.images
        ? Background
        : Root;

    return (
        <RootWrapper images={props.images}>
            <ContentWrapper>
                <Content>
                    <CenterWrapper>
                        <Header>{props.title}</Header>
                        <Description>
                            {props.description}
                        </Description>
                    </CenterWrapper>
                </Content>
            </ContentWrapper>
        </RootWrapper>
    )
};

export default PageHero;
