import styled, { css } from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { transparentize } from "polished";

import { leftAccentColorLighter } from '../../../styles/_theme-variables';
import { transitionColor, headerFontMixin, articleFont } from '../../../styles/_mixins';
import { fluidFontSizes } from '../../../styles/_calculations';

const heroBackgroundColor = props => transparentize(0.2, props.theme.styles.color.primaryColorDarker)
const textColor = leftAccentColorLighter;

const RootStyles = css`
    width: 100%;
    min-height: 33vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Root = styled.div`
    ${RootStyles}
`;

export const GatsbyBackgroundImage = styled(BackgroundImage)`
    ${RootStyles}
`;

export const ContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${heroBackgroundColor};
    border-bottom: 1px solid ${textColor};
`;

export const Content = styled.div`
    @media screen and (max-width: 64em) {
        padding: 0.5em;
    }
`;

export const Header = styled.h1`
    color: ${textColor};
    ${transitionColor};
    ${headerFontMixin};
    ${fluidFontSizes.header1Size}
    margin: 0;  
    margin-bottom: 0.5em;
`;

export const Description = styled.p`
    color: ${textColor};
    ${transitionColor};
    ${articleFont};
    ${fluidFontSizes.bodySize}
    margin: 0;  
`;