import styled from 'styled-components';
import { fluidFontSizes } from '../../../../styles/_calculations';
import { headerFontMixin, transitionColor, articleFont, transitionAll } from '../../../../styles/_mixins';

import BaseElements from './_base';
import { TileElements } from './tile-elements';
import { textSecondary, borderRadius, basicBlack, grey, leftAccentColorLighter, basicWhite } from '../../../../styles/_theme-variables';

const { Root, TextArea, SubTitle, Title, StyledImage, LinkWrapper, Text, ImageWrapper } = BaseElements;

const TileBgLight = grey;
const TileBgDark = 'transparent';
const TileTextColor = basicBlack;
const TileTextColorHover = textSecondary;

const TileTextColorDark = leftAccentColorLighter;
const TileTextColorDarkHover = basicWhite;

const ThemedRoot = styled(Root)`
    background-color: ${TileBgLight};
    border: 4px solid ${TileBgLight};
    border-radius: ${borderRadius};
    overflow: hidden;
`;

const DarkThemedRoot = styled(ThemedRoot)`
    background-color: ${TileBgDark};
    border: 4px solid ${TileBgDark};
`;

const ThemedTextArea = styled(TextArea)`
    ${fluidFontSizes.bodySize};
    padding: 0.75em;
`;

const ThemedTitle = styled(Title)`
    ${headerFontMixin};
    ${fluidFontSizes.header5Size};
    color: ${TileTextColor};
    ${transitionColor};

    a:hover & {
        color: ${TileTextColorHover};
    }   
`;

const ThemedTitleDark = styled(ThemedTitle)`
    color: ${TileTextColorDark};
    a:hover & {
        color: ${TileTextColorDarkHover};
    }   
`;

const ThemedSubTitle = styled(SubTitle)`
    ${articleFont};
    ${fluidFontSizes.smallPrintSize};
    color: ${TileTextColor};
    ${transitionColor};

    a:hover & {
        color: ${TileTextColorHover};
    }   
`;

const ThemedSubTitleDark = styled(ThemedSubTitle)`
    color: ${TileTextColorDark};
    a:hover & {
        color: ${TileTextColorDarkHover};
    }   
`;

const ThemedStyledImage = styled(StyledImage)`
    ${transitionAll};
    opacity: 0.8;

    ${Root}:hover & {
        opacity: 1;
        transform: scale(1.2);
    }
`;

export const ThemedImageWrapper = styled(ImageWrapper)`
    border-radius: ${borderRadius};
`;

export const ThemedTileElements: TileElements = {
    Root: ThemedRoot,
    LinkWrapper,
    TextArea: ThemedTextArea,
    Text,
    Title: ThemedTitle,
    ImageWrapper: ThemedImageWrapper,
    SubTitle: ThemedSubTitle,
    StyledImage: ThemedStyledImage
};

export const DarkThemedTileElements: TileElements = {
    Root: DarkThemedRoot,
    LinkWrapper,
    TextArea: ThemedTextArea,
    Text,
    Title: ThemedTitleDark,
    ImageWrapper: ThemedImageWrapper,
    SubTitle: ThemedSubTitleDark,
    StyledImage: ThemedStyledImage
};
