import BaseElements from "./_base";
import { ThemedTileElements, DarkThemedTileElements } from './default';
import { TileElements } from './tile-elements';

export class TileElementFactory {

    private themeContext: any;

    constructor(context: any) {
        this.themeContext = context;
    }

    getElements(): TileElements {
        const contextElements: TileElements = this.themeContext.key === 'default'
            ? this.themeContext.darkMode
                ? DarkThemedTileElements
                : ThemedTileElements
            : BaseElements;

        return contextElements;
    }
}