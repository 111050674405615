import styled, { css } from "styled-components";
import { bpMobilePhablet, textPrimary, darkGrey } from '../_theme-variables';
import { articleFont, transitionColor } from "../_mixins";
import { fluidFontSizes } from '../_calculations';

const projectsBackgroundColor = darkGrey;
const projectsBackgroundColorDark = darkGrey;

export const pageWrapperStyle = css`
    background-color: ${props => props.theme.darkMode
        ? projectsBackgroundColorDark
        : projectsBackgroundColor
    };
`;

export const Description = styled.p`
    color: ${textPrimary};
    ${transitionColor};
    ${articleFont};
    ${fluidFontSizes.bodySize}
    padding: 1em 0;
`;

export const Projects = styled.div`
    ${fluidFontSizes.bodySize}
    display: grid;

    @media screen and (min-width: ${bpMobilePhablet}) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-gap: 3em;
        margin-bottom: 3em;
        padding: 1em 3em;
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-gap: 2em;
        margin-bottom: 2em;
        padding: 0 2em;
    }

    @media screen and (max-width: 38em) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(1, 1fr);
        grid-gap: 2em;
        margin-bottom: 0.5em;
        padding: 0 0.5em;
    }
`;

export const ProjectsItem = styled.div`
    grid-column: span 1;
    grid-row: span 1;
`;