import React from "react";
import { TileElementFactory } from './tile-styles/element.factory';
import { resolveMonth } from "../../../framework/date-resolver";
import { TileElements } from "./tile-styles/tile-elements";
import { css } from "styled-components";
import { withContextElements, withEscapeHatchStyles } from '../../../styles/sprinkles';

export interface TileProps {
    elements?: TileElements;
    title: string;
    subtitle: string;
    date: string;
    slug: string;
    image: any;
    logo: any;
}

// Style Override Approach 2: Provide an escape hatch style
const rootStyleOverride = css``;

const Tile = (props: TileProps) => {
    const {
        Root: BaseRoot,
        Title,
        SubTitle,
        ImageWrapper,
        TextArea,
        StyledImage,
        Text,
        LinkWrapper: Link
    } = props.elements;
    
    const mapImage = ({ src, name }) => (
        <StyledImage
            fluid={src.childImageSharp.fluid}            
            alt={name}
        />
    );

    const { title, image, subtitle, date, slug, logo } = props;
    const projectDate = new Date(date);

    const Root = withEscapeHatchStyles(BaseRoot);

    return (
        // Style Override Approach 2: Inject escape styles into invidiual elements
        <Root escapeHatchStyles={rootStyleOverride}>
            <Link to={`portfolio/${slug}`}>
                <ImageWrapper>
                    { mapImage(image) }
                </ImageWrapper>
                <TextArea>
                    <Text>
                        <Title>{ title }</Title>
                        <SubTitle>{ subtitle } - { resolveMonth(projectDate) } { projectDate.getUTCFullYear() }</SubTitle>
                    </Text>
                </TextArea>
            </Link>
        </Root>
    )
};

// Style Override Approach 4: Wrap the component in a context driven factory to drive the elements used
export default withContextElements(Tile)(TileElementFactory);

// Style Override Approach 3: Inject a static collection of elements as render props
// export default withRenderPropElements(Tile)(ThemedTileElements);

// Apprpach 2: Inject the component with an escape hatch stylesheet
// export default withEscapeHatchStylesheet(Tile)(MyStyleOverrides)

// Approch 1: Use the theme context to turn the dials on theme token variables
// export default Tile;
