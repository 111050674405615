import React from "react";
import { graphql } from 'gatsby';

import SEO from "../../components/seo";
import PageHero from "../../components/_organisms/page-hero";
import PageWrapper from "../../components/_molecules/page-wrapper";
import Tile from "../../components/_molecules/tile";

import {
  Projects,
  ProjectsItem,
  pageWrapperStyle
} from "../../styles/pages/portfolio-styles";

const PortfolioPage = ({ data }) => {
  const mappedContentfulProjects = {
    group: data.contentfulProjects.group.map(g => ({
      field: g.field,
      fieldValue: g.fieldValue,
      edges: g.edges.map(p => ({
        node: {
          id: p.node.id,
          title: p.node.title,
          metaDescription: p.node.metaDescription.metaDescription,
          slug: p.node.slug,
          logo: {
            name: p.node.logo.name,
            src: {
              childImageSharp: {
                fluid: p.node.logo.fluid,
                portrait: p.node.logo.portrait
              },
              extension: p.node.logo.file.contentType,
              publicURL: p.node.logo.file.url
            }
          },
          heroImages: p.node.heroImages.map(i => ({
            name: i.name,
            src: {
              childImageSharp: {
                fluid: i.fluid,
              }
            }
          })),
          date: p.node.date,
          series: p.node.series
        }
      }))
    }))
  } 

  const projects = data.projects;

  return (
    <>
      <SEO
        title={data.portfolioLanding.title}
        description={data.portfolioLanding.metaDescription}
        image={(data.portfolioLanding.openGraphImage && data.portfolioLanding.openGraphImage[0].src.childImageSharp.fixed.src) || null}
        slug="/portfolio"
      />
      <PageHero
          title={data.portfolioLanding.title}
          images={data.portfolioLanding.heroImages}
          description={data.portfolioLanding.metaDescription}
      />
      <PageWrapper escapeHatchStylesheet={{ root: pageWrapperStyle }}>        
        <Projects>
          {
            data.portfolioLanding.series.map(s => {
              const group = projects.group.find(g => g.fieldValue.toLowerCase() === s);
              return group
                ? group.edges.map(({ node }) => (
                    <ProjectsItem>
                      <Tile
                        slug={node.slug}
                        subtitle={group.fieldValue}
                        title={node.title}
                        date={node.date}
                        image={node.heroImages[0]}
                        logo={node.logo}
                      />
                    </ProjectsItem>
                  ))
                : null
            })
          }
          {
            data.portfolioLanding.series.map(s => {
              const group = mappedContentfulProjects.group.find(g => g.fieldValue.toLowerCase() === s);
              return group
                ? group.edges.map(({ node }) => (
                    <ProjectsItem>
                      <Tile
                        slug={node.slug}
                        subtitle={group.fieldValue}
                        title={node.title}
                        date={node.date}
                        image={node.heroImages[0]}
                        logo={node.logo}
                      />
                    </ProjectsItem>
                  ))
                : null
            })
          }
        </Projects>
      </PageWrapper>
    </>
  )
};

export const pageQuery = graphql`
    query portfolioLandingQuery {
        portfolioLanding: jsonJson(id: {eq: "portfolio_page"}) {
          id
          title
          metaDescription
          heroImages {
            name
            src {
                childImageSharp {
                    fixed(width: 700, height: 350, cropFocus: NORTH) {
                        ...GatsbyImageSharpFixed
                    }  
                    fluid(quality: 90, maxWidth: 1920, maxHeight: 350) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                    fluidThumb: fluid(quality: 90, maxWidth: 1920, maxHeight: 1080, cropFocus: NORTH) {
                        ...GatsbyImageSharpFluid_withWebp
                    }               
                }   
            }
          }
          openGraphImage: heroImages {
            name
            src {
              childImageSharp {
                fixed(width: 1200, height: 630) {
                  src
                }
              }
            }
          }
          bodyContent: _bodyContent          
          series
        }
        contentfulProjects: allContentfulProject {
          group(field: series) {
            field
            fieldValue
            edges {
              node {
                id
                title
                metaDescription {
                  id
                  metaDescription
                }
                slug
                logo {
                  name: title
                  fluid (maxHeight: 120) {
                    ...GatsbyContentfulFluid
                  }
                  portrait: fluid(maxWidth: 120, maxHeight: 120) {
                    ...GatsbyContentfulFluid
                  }
                  file {
                    contentType
                    url
                  }
                }
                heroImages {
                  name: title
                  fluid(maxWidth: 414, maxHeight: 180, cropFocus: TOP) {
                    ...GatsbyContentfulFluid
                  }
                }
                date: projectDate
                series
              }
            }
          }
        }
        projects: allProjectsJson {
          group(field: series) {
            field
            fieldValue
            edges {
              node {
                id
                title
                metaDescription
                slug
                logo {
                  name
                  src {
                    childImageSharp {
                      fluid(maxHeight: 120) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                      portrait: fluid(maxWidth: 120, maxHeight: 120) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                    extension
                    publicURL
                  }
                }
                heroImages {
                  name
                  src {
                    childImageSharp {
                        fluid(maxWidth: 414, maxHeight: 180, cropFocus: NORTH) {
                          ...GatsbyImageSharpFluid_noBase64
                        }                  
                    }   
                  }
                }
                date          
                series
              }
            }
          }
        }
      }
      `;

export default PortfolioPage