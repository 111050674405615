import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from 'gatsby';

import { basicWhite } from '../../../../styles/_theme-variables';
import { TileElements } from './tile-elements';

const Root = styled.div`
    background-color: ${basicWhite};
    height: 100%;

    & a {
        text-decoration: none;
    }
`;

const LinkWrapper = styled(Link)`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const TextArea = styled.div`
    flex: 1;
    text-align: center;
`;

const Text = styled.div`
`;

const Title = styled.p`
    display: block;    
    margin-bottom: 0.5em;
`;

const SubTitle = styled.p`
    display: block;
`;

const ImageWrapper = styled.div`
    overflow: hidden;
`;

const StyledImage = styled(Img)``;

const BaseElements: TileElements = {
    Root,
    LinkWrapper,
    TextArea,
    Text,
    Title,
    ImageWrapper,
    SubTitle,
    StyledImage
};

export default BaseElements;